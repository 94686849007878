// extracted by mini-css-extract-plugin
export var bodyBase = "saas-memberships-details-module--body-base--3a674 saas-memberships-details-module--site-font--e4ecd";
export var bodyLarge = "saas-memberships-details-module--body-large--222ce saas-memberships-details-module--body-base--3a674 saas-memberships-details-module--site-font--e4ecd";
export var bodyLargeBold = "saas-memberships-details-module--body-large-bold--0b95e saas-memberships-details-module--body-base--3a674 saas-memberships-details-module--site-font--e4ecd";
export var bodyRegular = "saas-memberships-details-module--body-regular--75535 saas-memberships-details-module--body-base--3a674 saas-memberships-details-module--site-font--e4ecd";
export var bodyRegularBold = "saas-memberships-details-module--body-regular-bold--a1c60 saas-memberships-details-module--body-base--3a674 saas-memberships-details-module--site-font--e4ecd";
export var bodySmall = "saas-memberships-details-module--body-small--e4605 saas-memberships-details-module--body-base--3a674 saas-memberships-details-module--site-font--e4ecd";
export var bodySmallBold = "saas-memberships-details-module--body-small-bold--6cbbc saas-memberships-details-module--body-base--3a674 saas-memberships-details-module--site-font--e4ecd";
export var borderTop = "saas-memberships-details-module--border-top--cf115";
export var breakWordContainer = "saas-memberships-details-module--break-word-container--cfe1d";
export var buttonIconBase = "saas-memberships-details-module--button-icon-base--17d8e";
export var clickLink = "saas-memberships-details-module--click-link--eadfd";
export var dropdownBase = "saas-memberships-details-module--dropdown-base--adf20";
export var dropdownSelectBase = "saas-memberships-details-module--dropdown-select-base--e3d73 saas-memberships-details-module--text-input--5a8cb";
export var filterWidth = "saas-memberships-details-module--filterWidth--42764";
export var flexCol = "saas-memberships-details-module--flex-col--3ac56";
export var formErrorMessage = "saas-memberships-details-module--form-error-message--adaf9";
export var h3 = "saas-memberships-details-module--h3--26e36";
export var h4 = "saas-memberships-details-module--h4--9fa5d";
export var hoverLink = "saas-memberships-details-module--hover-link--9a29d";
export var hoverRow = "saas-memberships-details-module--hover-row--2814f";
export var membershipContainer = "saas-memberships-details-module--membership-container--4eeb7 saas-memberships-details-module--flex-col--3ac56 saas-memberships-details-module--primary-border--b5762";
export var membershipHeader = "saas-memberships-details-module--membership-header--84219";
export var membershipHeading = "saas-memberships-details-module--membership-heading--7d2a9";
export var membershipLabel = "saas-memberships-details-module--membership-label--b18cc";
export var moreFiltersBorderClass = "saas-memberships-details-module--more-filters-border-class--b868c";
export var pageBg = "saas-memberships-details-module--page-bg--30eff";
export var pointer = "saas-memberships-details-module--pointer--0efca";
export var primaryBorder = "saas-memberships-details-module--primary-border--b5762";
export var shadowBoxLight = "saas-memberships-details-module--shadow-box-light--378ae";
export var siteFont = "saas-memberships-details-module--site-font--e4ecd";
export var slideDownAndFade = "saas-memberships-details-module--slideDownAndFade--1368b";
export var slideLeftAndFade = "saas-memberships-details-module--slideLeftAndFade--29c23";
export var slideRightAndFade = "saas-memberships-details-module--slideRightAndFade--d5dea";
export var slideUpAndFade = "saas-memberships-details-module--slideUpAndFade--d25da";
export var statusDecoration = "saas-memberships-details-module--status-decoration--e9ba7";
export var textInput = "saas-memberships-details-module--text-input--5a8cb";
export var textInverted = "saas-memberships-details-module--text-inverted--b0012";
export var textMediumDark = "saas-memberships-details-module--text-medium-dark--01d2e";
export var tooltipFont = "saas-memberships-details-module--tooltipFont--8dd96";
export var unstyledButton = "saas-memberships-details-module--unstyled-button--bf760";