import React from 'react';

import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { RouterLink } from 'src/components/button/link';
import Dropdown, { Option } from 'src/components/dropdown/dropdown';
import Panel from 'src/components/panel/panel';
import StatusLabel from 'src/components/status-label/status-label';
import { Body } from 'src/components/typography/typography';
import { MembershipTermStatuses } from 'src/graphql-types/globalSaasTypes';
import { GetMembershipOwningOrg_getOrganisationById } from 'src/graphql-types/saas/GetMembershipOwningOrg';
import { Membership_membership, Membership_membership_terms } from 'src/graphql-types/saas/Membership';
import ROUTES from 'src/utils/routes';

import { BodySmall, BodySmallBold, H2, Spinner } from '@clubspark-react/clubspark-react-tools';

import * as styles from './saas-memberships-header.module.less';

interface TcMembershipHeaderProps {
  owningOrg?: GetMembershipOwningOrg_getOrganisationById | null;
  membership?: Membership_membership | null;
  selectedTerm?: Membership_membership_terms;
  termOptions: Option[];
  setSelectedTerm: (value: string) => void;
}
export const SaasMembershipHeader: React.FC<TcMembershipHeaderProps> = ({
  owningOrg,
  membership,
  selectedTerm,
  termOptions,
  setSelectedTerm,
}) => {
  const { t } = useTranslation();

  if (!owningOrg || !membership)
    return (
      <Panel>
        <Spinner />
      </Panel>
    );

  return (
    <Panel>
      <Grid container justifyContent="space-between" alignItems="center" alignContent="center">
        <Grid container>
          <Grid container item alignItems="center" justifyContent="space-between">
            <div className={styles.nameStyle}>
              <H2>{`${membership.name}: `}</H2>
              <Dropdown
                options={termOptions}
                disabled={!termOptions}
                value={selectedTerm?.termId ?? ''}
                selected={selectedTerm?.termId ?? ''}
                onSelect={(o) => setSelectedTerm(o.value)}
                flat
                spacing={{ margins: { xxs: 'top', xs: 'left' } }}
                selectClassname={styles.dropdown}
              />
            </div>
            <RouterLink to={`${ROUTES.SAAS_MEMBERSHIPS}/${membership.membershipId}/edit`} level="tertiary">
              {t('edit membership')}
            </RouterLink>
          </Grid>
          <Body size="lg">{owningOrg.organisationName}</Body>
          <Divider className={styles.divider} />
          <Grid container direction="row" alignItems="center">
            <Grid item xs={6} sm={6} md={6} lg={2}>
              <BodySmallBold>{t('Code')}</BodySmallBold>
              <BodySmall>{membership.code}</BodySmall>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={2}>
              <BodySmallBold>{t('Type')}</BodySmallBold>
              <BodySmall>{t(`membershipType.${membership.type}`)}</BodySmall>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={2}>
              <BodySmallBold>{t('start date')}</BodySmallBold>
              <BodySmall>{t('term date format', { date: dayjs.utc(selectedTerm?.startDate) })}</BodySmall>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={2}>
              <BodySmallBold>{t('end date')}</BodySmallBold>
              <BodySmall>{t('term date format', { date: dayjs.utc(selectedTerm?.endDate) })}</BodySmall>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={2}>
              <BodySmallBold>{t('status')}</BodySmallBold>
              <StatusLabel variety={selectedTerm?.status === MembershipTermStatuses.PUBLIC ? 'success' : 'warning'}>
                {t(`termStatus.${selectedTerm?.status}`) ?? ''}
              </StatusLabel>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Panel>
  );
};
