import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import { navigate } from 'gatsby';
import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SaasMembershipDetails from 'src/components/saas-memberships/saas-membership-details/saas-membership-details';
import SEO from 'src/components/seo';

const TcMembershipDetailsPage = () => {
  return (
    <Router basepath="/saas-memberships">
      <SaasMembershipDetailsRoute path="/:membershipId" />
    </Router>
  );
};

interface SaasMembershipRouteProps extends RouteComponentProps {
  membershipId?: string;
}

const SaasMembershipDetailsRoute: React.FC<SaasMembershipRouteProps> = ({ membershipId }) => {
  if (!membershipId) {
    navigate('/404');
    return null;
  } else {
    return (
      <Layout>
        <SEO title="Membership Details" />
        <PrivateRoute>
          <SaasMembershipDetails membershipId={membershipId} />
        </PrivateRoute>
      </Layout>
    );
  }
};

export default TcMembershipDetailsPage;
