export const getFilterMembersQueryVarsFromContext = (ctx, membershipId: string, termId: string) => {
  const { paymentStatus, search, memberStatus } = ctx.filters;
  const { limit, offset } = ctx.paging;
  return {
    filter: {
      memberStatus: memberStatus ? { in: [memberStatus] } : undefined,
      paymentStatus: paymentStatus ? { in: [paymentStatus] } : undefined,
      organisationName: search ? { contains: search } : undefined,
      membershipId: membershipId,
      termId: termId,
    },
    pagination: {
      limit,
      skip: offset,
    },
    includeTotal: true,
  };
};
