import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Link, navigate } from 'gatsby';
import { print } from 'graphql';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import Breadcrumbs from 'src/components/breadcrumbs/breadcrumbs';
import ErrorPanel from 'src/components/error-panel/error-panel';
import { CSVTransform } from 'src/components/generate-report/generate-report';
import Panel from 'src/components/panel/panel';
import {
  GET_MEMBERSHIP,
  GET_MEMBERSHIP_MEMBERS,
  GET_MEMBERSHIP_OWNING_ORG,
} from 'src/components/saas-memberships/saas-memberships-queries';
import StatusLabel, { LabelVariety } from 'src/components/status-label/status-label';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { getEnvConfig } from 'src/config/config';
import { MembershipTermStatuses, MemberStatus, PaymentStatus } from 'src/graphql-types/globalSaasTypes';
import {
  GetMembershipMembers,
  GetMembershipMembers_findMembers_results,
} from 'src/graphql-types/saas/GetMembershipMembers';
import { GetMembershipOwningOrg, GetMembershipOwningOrgVariables } from 'src/graphql-types/saas/GetMembershipOwningOrg';
import { Membership, Membership_membership_terms, MembershipVariables } from 'src/graphql-types/saas/Membership';
import { Spacing } from 'src/hooks/spacing';
import { initQueryFilterTableHook } from 'src/hooks/use-query-filter-table';
import { SEARCH_PLAYERS_DOWNLOAD } from 'src/queries/ITA';
import { sortByDate } from 'src/utils/helper';
import ROUTES from 'src/utils/routes';

import { getFilterMembersQueryVarsFromContext } from './members-table-filter';
import { SaasMembershipHeader } from './saas-membership-header';
import * as styles from './saas-memberships-details.module.less';

type TableItem = GetMembershipMembers_findMembers_results & { id: string };
const useQFT = initQueryFilterTableHook<GetMembershipMembers, TableItem>(GET_MEMBERSHIP_MEMBERS);
const filterSpacing: Spacing = {
  base: 6,
  margins: {
    xs: ['bottom', 'right'],
  },
};
const accessoriesBarSpacing: Spacing = {
  base: 5,
  margins: {
    xs: 'top',
    md: 'bottom',
  },
};

const getCsvTransforms = (t: TFunction): CSVTransform[] => [
  {
    key: 'id',
    label: t('person id'),
  },
  {
    key: 'email',
    label: t('email'),
  },
];

export function convertPaymentStatusToTagVariety(paymentStatus: PaymentStatus): LabelVariety | undefined {
  switch (paymentStatus) {
    case PaymentStatus.PAID:
      return 'success';
    case PaymentStatus.PENDING:
      return 'warning';
    case PaymentStatus.FAILED:
    case PaymentStatus.REFUNDED:
    case PaymentStatus.CANCELLED:
    case PaymentStatus.NOTPAID:
      return 'error';
    default:
      return undefined;
  }
}

export function convertMemberStatusToTagVariety(paymentStatus: MemberStatus): LabelVariety | undefined {
  switch (paymentStatus) {
    case MemberStatus.ACTIVE:
      return 'success';
    case MemberStatus.PENDING:
      return 'warning';
    case MemberStatus.CANCELLED:
      return 'error';
    default:
      return undefined;
  }
}

interface TcMembershipDetailsProps {
  membershipId: string;
}
const SaasMembershipDetails: React.FC<TcMembershipDetailsProps> = ({ membershipId }) => {
  const { t } = useTranslation();
  const [selectedTerm, setSelectedTerm] = useState<Membership_membership_terms>();
  const [errorText, setErrorText] = useState<null | string>(null);

  const { data: membership } = useQuery<Membership, MembershipVariables>(GET_MEMBERSHIP, {
    client: meshGatewayClient,
    variables: {
      membershipId,
    },
    onCompleted: ({ membership }) => {
      if (!membership) navigate('/404');
    },
    onError: (err) => {
      setErrorText(
        t('queryError', {
          type: 'membershipsModule.queryErrors.getMembership',
          message: err.message,
        }),
      );
    },
  });
  const { data: owningOrg } = useQuery<GetMembershipOwningOrg, GetMembershipOwningOrgVariables>(
    GET_MEMBERSHIP_OWNING_ORG,
    {
      client: meshGatewayClient,
      variables: {
        organisationId: membership?.membership?.region ?? '',
      },
      skip: !membership?.membership?.region,
      onError: (err) => {
        setErrorText(
          t('queryError', {
            type: 'membershipsModule.queryErrors.getMembershipOwningOrg',
            message: err.message,
          }),
        );
      },
    },
  );

  const { sortedTerms, termOptions } = useMemo(() => {
    const sortedTerms = membership?.membership?.terms
      ? sortByDate(membership.membership.terms, { fieldName: 'startDate', order: 'DESC' })
      : [];

    return {
      sortedTerms,
      termOptions: sortedTerms.map((term) => ({ label: term.name, value: term.termId })),
    };
  }, [membership?.membership?.terms]);

  const {
    components: { FilterBar, AdminTable, AccessoryBar, props },
    query: { error: tableError },
  } = useQFT(
    {
      search: {
        position: 1,
        type: 'search',
        initialValue: '',
        props: {
          onClear: (ctx) => {
            ctx.setValues({
              [ctx.filterKey]: '',
            });
          },
          placeholder: t('members search'),
          delay: 500,
        },
        spacing: filterSpacing,
      },
      memberStatus: {
        position: 2,
        type: 'select',
        initialValue: '',
        props: {
          options: [
            { label: t('member status'), value: '' },
            { label: t('memberStatusOptions.ACTIVE'), value: MemberStatus.ACTIVE },
            { label: t('memberStatusOptions.PENDING'), value: MemberStatus.PENDING },
            { label: t('memberStatusOptions.CANCELLED'), value: MemberStatus.CANCELLED },
          ],
          classNames: {
            trigger: styles.filterWidth,
          },
        },
        spacing: filterSpacing,
      },
      paymentStatus: {
        position: 3,
        type: 'select',
        initialValue: '',
        props: {
          options: [
            { label: t('payment status'), value: '' },
            { label: t('paymentStatusOptions.PAID'), value: PaymentStatus.PAID },
            { label: t('paymentStatusOptions.PENDING'), value: PaymentStatus.PENDING },
            { label: t('paymentStatusOptions.REFUNDED'), value: PaymentStatus.REFUNDED },
            { label: t('paymentStatusOptions.FAILED'), value: PaymentStatus.FAILED },
            { label: t('paymentStatusOptions.CANCELLED'), value: PaymentStatus.CANCELLED },
            { label: t('paymentStatusOptions.NOTPAID'), value: PaymentStatus.NOTPAID },
          ],
          classNames: {
            trigger: styles.filterWidth,
          },
        },
        spacing: filterSpacing,
      },
    },
    {
      columns: [
        {
          key: 'organisation',
          title: t('member'),
          getValue: (item) => (
            <Link to={`organisation/${item.organisationId}?termId=${selectedTerm?.termId}`}>
              {item.organisationName ?? 'not set'}
            </Link>
          ),
        },
        {
          key: 'amountPaid',
          title: t('amount paid'),
          getValue: (item) =>
            new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD' }).format(
              item?.payment?.amountPaid ? item?.payment?.amountPaid / 100 : 0,
            ),
        },
        {
          key: 'purchaseDate',
          title: t('purchase date'),
          getValue: (item) => {
            const date = dayjs(item.payment?.date).local();
            return t('purchase date format', { date });
          },
        },
        {
          key: 'paymentStatus',
          title: t('payment status'),
          getValue: (item) =>
            item.payment?.status ? (
              <StatusLabel variety={convertPaymentStatusToTagVariety(item.payment.status)}>
                {t(`paymentStatusOptions.${item.payment.status}`)}
              </StatusLabel>
            ) : (
              ''
            ),
        },
        {
          key: 'memberStatus',
          title: t('member status'),
          getValue: (item) =>
            item.memberStatus ? (
              <StatusLabel variety={convertMemberStatusToTagVariety(item.memberStatus)}>
                {t(`memberStatusOptions.${item.memberStatus}`)}
              </StatusLabel>
            ) : (
              ''
            ),
        },
      ],
      accessoryBar: {
        items: [
          {
            type: 'btn-download-auto',
            position: 'right',
            props: (ctx) => ({
              generateButtonTitle: t('download table csv'),
              paginator: { rootFieldPath: 'players.results' },
              reportQuery: print(SEARCH_PLAYERS_DOWNLOAD),
              reportQueryEndpoint: getEnvConfig().MESH_GATEWAY_GQL_URL,
              reportQueryVariables: getFilterMembersQueryVarsFromContext(
                ctx,
                membershipId,
                selectedTerm?.termId as string,
              ),
              filename: 'players',
              csvTransforms: getCsvTransforms(t),
              csvFormatOptions: { disableUnwind: true },
              buttonProps: {
                level: 'tertiary',
                disabled: false,
                icon: 'sm-download',
              },
            }),
          },
        ],
        gap: 12,
        spacing: accessoriesBarSpacing,
      },
    },
    {
      depsTableConfig: [selectedTerm],
      columnSelectorId: 'memberships-members',
      mapFiltersToQueryOptions: ({ filters, helper }) => {
        return {
          client: meshGatewayClient,
          skip: !selectedTerm,
          getTotalItems: (data) => data?.findMembers?.total ?? 0,
          transformVariables: (vars: { limit?: number; offset?: number }) => {
            const { limit, offset } = vars;
            return getFilterMembersQueryVarsFromContext(
              {
                filters,
                paging: { limit, offset },
                helper,
              },
              membershipId,
              selectedTerm?.termId as string,
            );
          },
        };
      },
      mapDataToTable: (data) => data?.findMembers?.results as TableItem[],
    },
  );

  const onDropdownChange = useCallback(
    (value) => {
      setSelectedTerm(sortedTerms.find((term) => term.termId === value));
    },
    [sortedTerms],
  );

  useEffect(() => {
    const latestActiveTerm = sortedTerms.find(({ status }) => status === MembershipTermStatuses.PUBLIC);
    if (sortedTerms.length) setSelectedTerm(latestActiveTerm ?? sortedTerms[0]);
  }, [sortedTerms]);

  if (errorText) return <ErrorPanel details={errorText} />;

  return (
    <PageMaxWidth>
      <Breadcrumbs
        paths={[
          { name: t('membership list'), to: `${ROUTES.SAAS_MEMBERSHIPS}`, highlight: true },
          {
            name: membership?.membership?.name ?? '',
            active: true,
          },
        ]}
      />
      <SaasMembershipHeader
        owningOrg={owningOrg?.getOrganisationById}
        membership={membership?.membership}
        selectedTerm={selectedTerm}
        termOptions={termOptions}
        setSelectedTerm={onDropdownChange}
      />
      <Panel headerEndContent={<AccessoryBar {...props.accessoryBar} />} title={t('members')}>
        <FilterBar {...props.filterBar} />
        <AdminTable
          hideTopPaginationInfo
          {...props.adminTable}
          error={
            tableError
              ? t('queryError', {
                  type: 'membershipsModule.queryErrors.getMembershipMembers',
                  message: tableError.message,
                })
              : undefined
          }
        />
      </Panel>
    </PageMaxWidth>
  );
};

export default SaasMembershipDetails;
