// extracted by mini-css-extract-plugin
export var bodyBase = "saas-memberships-header-module--body-base--39986 saas-memberships-header-module--site-font--3db6c";
export var bodyLarge = "saas-memberships-header-module--body-large--ea518 saas-memberships-header-module--body-base--39986 saas-memberships-header-module--site-font--3db6c";
export var bodyLargeBold = "saas-memberships-header-module--body-large-bold--c2d07 saas-memberships-header-module--body-base--39986 saas-memberships-header-module--site-font--3db6c";
export var bodyRegular = "saas-memberships-header-module--body-regular--b9efc saas-memberships-header-module--body-base--39986 saas-memberships-header-module--site-font--3db6c";
export var bodyRegularBold = "saas-memberships-header-module--body-regular-bold--ac54b saas-memberships-header-module--body-base--39986 saas-memberships-header-module--site-font--3db6c";
export var bodySmall = "saas-memberships-header-module--body-small--c43d6 saas-memberships-header-module--body-base--39986 saas-memberships-header-module--site-font--3db6c";
export var bodySmallBold = "saas-memberships-header-module--body-small-bold--a3be5 saas-memberships-header-module--body-base--39986 saas-memberships-header-module--site-font--3db6c";
export var borderTop = "saas-memberships-header-module--border-top--a0a9f";
export var breakWordContainer = "saas-memberships-header-module--break-word-container--06f5c";
export var buttonIconBase = "saas-memberships-header-module--button-icon-base--719f9";
export var clickLink = "saas-memberships-header-module--click-link--2a4b7";
export var divider = "saas-memberships-header-module--divider--5d2a4";
export var dropdown = "saas-memberships-header-module--dropdown--9de2e";
export var dropdownBase = "saas-memberships-header-module--dropdown-base--fecee";
export var dropdownSelectBase = "saas-memberships-header-module--dropdown-select-base--fdc2a saas-memberships-header-module--text-input--c75b8";
export var flexCol = "saas-memberships-header-module--flex-col--0a63f";
export var formErrorMessage = "saas-memberships-header-module--form-error-message--ded20";
export var h3 = "saas-memberships-header-module--h3--466c9";
export var h4 = "saas-memberships-header-module--h4--b37e6";
export var hoverLink = "saas-memberships-header-module--hover-link--da556";
export var hoverRow = "saas-memberships-header-module--hover-row--4572e";
export var membershipContainer = "saas-memberships-header-module--membership-container--a705b saas-memberships-header-module--flex-col--0a63f saas-memberships-header-module--primary-border--a6ce8";
export var membershipHeader = "saas-memberships-header-module--membership-header--5833f";
export var membershipHeading = "saas-memberships-header-module--membership-heading--fa367";
export var membershipLabel = "saas-memberships-header-module--membership-label--989c3";
export var moreFiltersBorderClass = "saas-memberships-header-module--more-filters-border-class--16cf4";
export var nameStyle = "saas-memberships-header-module--nameStyle--e05fe";
export var pageBg = "saas-memberships-header-module--page-bg--e32e0";
export var pointer = "saas-memberships-header-module--pointer--856b1";
export var primaryBorder = "saas-memberships-header-module--primary-border--a6ce8";
export var shadowBoxLight = "saas-memberships-header-module--shadow-box-light--b5dac";
export var siteFont = "saas-memberships-header-module--site-font--3db6c";
export var slideDownAndFade = "saas-memberships-header-module--slideDownAndFade--f2044";
export var slideLeftAndFade = "saas-memberships-header-module--slideLeftAndFade--2a6fb";
export var slideRightAndFade = "saas-memberships-header-module--slideRightAndFade--54a23";
export var slideUpAndFade = "saas-memberships-header-module--slideUpAndFade--64701";
export var statusDecoration = "saas-memberships-header-module--status-decoration--f0b9e";
export var textInput = "saas-memberships-header-module--text-input--c75b8";
export var textInverted = "saas-memberships-header-module--text-inverted--c467a";
export var textMediumDark = "saas-memberships-header-module--text-medium-dark--4c461";
export var tooltipFont = "saas-memberships-header-module--tooltipFont--89a88";
export var unstyledButton = "saas-memberships-header-module--unstyled-button--2da6a";